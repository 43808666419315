import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ClientRouter from '../ClientRouter';
import { loadable } from '../util/routeUtils';
import UserContextRoute from '../components/util/UserContextRoute';

const OnboardContainer = loadable(() => import('../pages/Onboard/OnboardContainer'));
const AddGiftsLandingContainer = loadable(() => import('../pages/AddGiftsLandingContainer'));
const ExistingUserAddGiftsLandingContainer = loadable(
  () => import('../pages/ExistingUserAddGiftsLandingContainer')
);
const Overview = loadable(() => import('../pages/Overview/Overview'));
const PlannerDrawerContainer = loadable(() => import('../pages/Overview/Overview')); // why is this here twice?
const PackDetailPage = loadable(() => import('../pages/StarterPacks/PackDetailPage'));
const GiftImportPage = loadable(() => import('../pages/GiftImport'));
const GiftImportSubmitPage = loadable(() => import('../pages/GiftImportSubmit'));
const AddGiftsPage = loadable(() => import('../pages/AddGifts/PreWedding'));
const PostWeddingShopPage = loadable(() => import('../pages/AddGifts/PostWedding'));
const ManageRegistryPage = loadable(() => import('../pages/ManageRegistry'));
const PackListingPage = loadable(() => import('../pages/StarterPacks/PackListingPage'));
const CheckoutPage = loadable(
  () => import('../pages/Checkout/CheckoutPageContainer/CheckoutPageContainer')
);
const PublicRegistryProductDetailsContainer = loadable(
  () => import('../pages/RegistryItemDetails/RegistryItemDetailsModule')
);
const RegistryImportFlowContainer = loadable(() => import('../pages/RegistryImportFlowV2'));
const RegistryImportLandingPage = loadable(
  () => import('../pages/RegistryImport/RegistryImportLandingPageContainer')
);
const ThirdPartyApp = loadable(() => import('../pages/ThirdPartyApp/ThirdPartyAppV2'));
const ChecklistLandingContainer = loadable(
  () => import('../pages/WeddingPlanning/WeddingChecklistLP/WeddingChecklistLpContainer')
);
const DigitalGreetingCardPage = loadable(() => import('../pages/DigitalGreetingCard'));
const ExternalProductInterstitialPage = loadable(
  () => import('../pages/ExternalProductInterstitial')
);
const NoMatch = loadable(() => import('../pages/NoMatch/NoMatch'));

const RegistryRoutes = () => (
  <ClientRouter>
    <Switch>
      <Route exact path="/onboard/new" component={OnboardContainer} />
      <Route exact path="/registry/import-gift" component={GiftImportPage} />
      <UserContextRoute
        auth
        hasRegistryAfterAuth
        exact
        path="/registry/submit"
        component={GiftImportSubmitPage}
      />
      <UserContextRoute
        auth
        hasRegistry
        exact
        path="/registry/add-gifts-landing"
        component={AddGiftsLandingContainer}
      />
      <UserContextRoute
        auth
        exact
        path="/registry/get-started"
        component={ExistingUserAddGiftsLandingContainer}
      />
      <UserContextRoute auth hasRegistry exact path="/registry/overview" component={Overview} />
      <UserContextRoute
        auth
        hasRegistry
        exact
        path="/registry/overview/planner"
        component={PlannerDrawerContainer}
      />
      <UserContextRoute
        auth
        hasRegistry
        exact
        path="/registry/quick-add-packs/:packKey"
        component={PackDetailPage}
      />
      <UserContextRoute
        auth
        hasRegistry
        exact
        path="/registry/add-gifts"
        component={AddGiftsPage}
      />
      <UserContextRoute
        auth
        hasRegistry
        exact
        path="/registry/shop"
        component={PostWeddingShopPage}
      />
      <UserContextRoute
        auth
        hasRegistry
        exact
        path="/registry/:registryKey/edit"
        component={ManageRegistryPage}
      />
      <UserContextRoute
        auth
        hasRegistry
        exact
        path="/registry/quick-add-packs"
        component={PackListingPage}
      />
      <UserContextRoute exact path="/registry/cart/checkout" component={CheckoutPage} />
      <Route
        exact
        path="/registry/:registryKey/zola-gift-card"
        component={PublicRegistryProductDetailsContainer}
      />
      <UserContextRoute
        exact
        path="/registry/collection-item/:collectionItemId"
        component={PublicRegistryProductDetailsContainer}
      />
      <UserContextRoute
        exact
        path="/wedding-registry/add-other-registry"
        component={RegistryImportFlowContainer}
      />
      <UserContextRoute
        exact
        path="/wedding-registry/add-from-other-stores"
        component={RegistryImportLandingPage}
      />
      <UserContextRoute exact path="/wedding-registry/apps" component={ThirdPartyApp} />
      <UserContextRoute
        exact
        path="/wedding-planning/checklist"
        component={ChecklistLandingContainer}
      />
      <UserContextRoute
        exact
        auth
        path="/registry/digital-greeting-card/:orderId"
        component={DigitalGreetingCardPage}
      />
      <Route
        exact
        path="/registry/digital-greeting-card/:orderId/token"
        component={DigitalGreetingCardPage}
      />
      <Route
        exact
        path="/registry/collection-item/:collectionItemId/external-redirect"
        component={ExternalProductInterstitialPage}
      />
      <Route component={NoMatch} />
    </Switch>
  </ClientRouter>
);

export default RegistryRoutes;
